export default [
	{
		code: 'en',
		file: 'en.js',
	},
	{
		code: 'de',
		file: 'de.js',
	},
	{
		code: 'fr',
		file: 'fr.js',
	},
];
